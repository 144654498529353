import React from "react";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";
import CookieConsent from "react-cookie-consent";

import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import "../styles/all.scss";
import useSiteMetadata from "./SiteMetadata";
import { withPrefix } from "gatsby";

const TemplateWrapper = ({ children }) => {
  const { title, description } = useSiteMetadata();
  return (
    <div>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />

        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}img/favicon-32x32.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}img/favicon-16x16.png`}
          sizes="16x16"
        />

        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />

        <script
          defer
          src="https://use.fontawesome.com/releases/v5.3.1/js/all.js"
        ></script>
      </Helmet>
      <Navbar />
      <div className="main-content container-fluid pt-2">
        {children}
        <Footer />
      </div>
      <CookieConsent
        location="bottom"
        buttonText="Yes"
        enableDeclineButton
        declineButtonText="No"
        cookieName="laasCookieConsentCookie"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        declineButtonStyle={{ fontSize: "13px" }}
      >
        We use cookies to enhance the user experience. You can find out more
        <Link to="cookies"> here</Link>. Is that OK?
      </CookieConsent>
    </div>
  );
};

export default TemplateWrapper;
